import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/css/iconfont.css' // 字体图标
import '@/css/reset.css' // 全局样式
import axios from 'axios';
import { pageUtilsInstall } from '@/utils/vueCommon'
import qs from 'qs'
import Config from './config'
import VueWechatTitle from 'vue-wechat-title' // 设置标签标题
import VCharts from 'v-charts' // 曲线图
// import 'lib-flexible/flexible.js'
import 'lodash' // js处理

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$axios = axios;
pageUtilsInstall(Vue);
Vue.prototype.qs = qs;
Vue.use(VueWechatTitle)
Vue.prototype.$config = Config
Vue._watchers = Vue.prototype._watchers = [] //v-charts组件出现（undefined (reading ‘map‘）bug解决方案
Vue.use(VCharts)

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')

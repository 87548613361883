import { setItem, getItem, getSession, setSession } from '@/utils/auth'
import config from '@/config'
function getSidebarStatus() {
    if (window.localStorage) {
        return Number(window.localStorage.sidebarStatus) === 1
    }
    return true
}
/* 页面刷新刷新获取tag tag为顶部菜单 */
function getCurrentTage() {
    if (getSession(config.sign + '-visitedViews')) {
        const userList = Object.keys(getSession(config.sign + '-visitedViews'))
        if (getItem(config.tokenSign + '-userInfo') && getItem(config.tokenSign + '-userInfo').name && userList.indexOf(getItem(config.tokenSign + '-userInfo').name) !== -1) {
            return getSession(config.sign + '-visitedViews')[getItem(config.tokenSign + '-userInfo').name]
        }
    }
    return []
}
const app = {
    state: {
        disMessageDrawer: false,
        visitedViews: getCurrentTage(),
    },
    mutations: {
        CLEAR_VISITED_VIEWS: (state, view) => {
            state.visitedViews = [{ name: '首页', path: '/index', fullPath: '/index' }]
            const info = getSession(config.sign + '-visitedViews') || {}
            info[getItem(config.tokenSign + '-userInfo').name] = state.visitedViews
            setSession(config.sign + '-visitedViews', info)
        },
        ADD_VISITED_VIEWS: (state, view) => {
            // 重登,首页处有添加首页tag,初始化覆盖
            if (!getItem(config.tokenSign + '-userInfo')) { return }
            const info = getSession(config.sign + '-visitedViews') || {}
            const arr = info[getItem(config.tokenSign + '-userInfo').name] || []
            if (state.visitedViews.findIndex(v => v.path === view.path) >= 0) return
            arr.push({ name: view.name, path: view.path, fullPath: view.fullPath })
            // 解决‘账号被顶下线或者超时退出登录，首页标签会有问题，有时候会出现了两个首页，有时候会首页消失’的问题
            let index = arr.findIndex(item => item.name === '首页')
            if (index !== -1) {
                arr.splice(index, 1)
            }
            arr.unshift({ name: '首页', path: '/index', fullPath: '/index' })
            const newarr = [...new Set(arr)]
            state.visitedViews = newarr
            info[getItem(config.tokenSign + '-userInfo').name] = newarr
            setSession(config.sign + '-visitedViews', info)
        },
        SET_DRAWER: (state, view) => {
            state.disMessageDrawer = view
        },
        // 标签页
        UPDATE_VISITED_VIEWS: (state, view) => {
            // 锁定和解锁
            state.visitedViews.forEach(v => {
                if (view.path !== '/index' && view.path === v.path) {
                    if (!v.isLock) v.isLock = true
                    else v.isLock = false
                }
            })
            state.visitedViews = state.visitedViews.filter(v => v.isLock && v.path !== '/index').concat(state.visitedViews.filter(v => !v.isLock && v.path !== '/index'))
            state.visitedViews.unshift({ name: '首页', path: '/index', fullPath: '/index' })
            // excuse me?下面这行代码,emmmmmm，我也不知道为啥之前的人这样子写，咱也不敢改，咱也没人问，就装作看不到吧
            // state.visitedViews = [...state.visitedViews]
            const info = getSession(config.sign + '-visitedViews') || {}
            info[getItem(config.tokenSign + '-userInfo').name] = state.visitedViews
            setSession(config.sign + '-visitedViews', info)
        },
        // 标签页
        DEL_VISITED_VIEWS: (state, view) => {
            let index
            for (const [i, v] of state.visitedViews.entries()) {
                if (v.path === view.path) {
                    index = i
                    break
                }
            }
            state.visitedViews.splice(index, 1)
            const info = getSession(config.sign + '-visitedViews') || {}
            info[getItem(config.tokenSign + '-userInfo').name] = state.visitedViews
            setSession(config.sign + '-visitedViews', info)
        },
    },
    actions: {
        addVisitedViews({ commit }, view) {
            // if (view.name === 'menu') {
            //     return false
            // }
            commit('ADD_VISITED_VIEWS', view)
        },
        setMessageDrawer({ commit }, view) {
            commit('SET_DRAWER', view)
        },
        delVisitedViews({ commit, state }, view) {
            return new Promise((resolve) => {
                commit('DEL_VISITED_VIEWS', view)
                resolve([...state.visitedViews])
            })
        },
        updateVisitedViews({ commit }, view) {
            commit('UPDATE_VISITED_VIEWS', view)
        },
        clearVisitedViews({ commit }, view) {
            commit('CLEAR_VISITED_VIEWS', view)
        },
    }

}

export default app
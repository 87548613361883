const getters = {
    disMessageDrawer: state => state.app.disMessageDrawer, // 隐藏显示消息记录
    token: state => state.user.token, // token身份
    name: state => state.user.name, // 用户昵称
    avatar: state => state.user.avatar, // 用户头像
    empId: state => state.user.empId, // 用户id
    mobileNo: state => state.user.mobileNo, // 用户账号
    role: state => state.permission.role, // 权限
    routes: state => state.data.routes, // 路由
    todoNum: state => state.user.todoNum, // 消息任务数
    // themeDataArr: state => state.data.themeDataArr, // 主题数据
    visitedViews: state => state.app.visitedViews,
}

export default getters
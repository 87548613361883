// 登录接口
import { get, post } from '@/utils/request';

// 获取企业名称及背景图
export function getThenant(params) {
    return get('/operate/getThenant', params);
}

// 验证码获取
export function captcha(params) {
    return get('/operate/captcha', params);
}

// 校验证手机号获取发送短信验证码的次数
export function captchaCount(params) {
    return get('/operate/sms/send/count', params);
}

// 发送短信验证码
export function sendShortMessage(params) {
    return post('/operate/sms/send', params, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

// 验证码校验接口
export function shortMessageValid(params) {
    return post('/operate/sms/valid', params, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

// 手机验证码登录接口
export function codelogin(params) {
    return post('/operate/codelogin', params, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

// 账号密码登录接口
export function login(params) {
    return post('/operate/login', params, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

// 退出登录接口
export function logout(params) {
    return post('/operate/logout', params);
}

// 获取是否有菜单的用户数据
export function getEmpOrModuleCount(params) {
    return get('/operate/getEmpOrModuleCount', params);
}

// 新增超级管理员接口
export function getCreateAdmin(params) {
    return post('/operate/emp/createAdmin', params, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

// 查询员工关联的功能菜单
export function getModulesStructure(params) {
    return get('/operate/emp/modulesStructure', params);
}
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

const Layout = () => import(/* webpackChunkName: 'Layout' */ '../views/layout/Layout')  // 公共头部、左侧菜单、主体内容框架
const Login = () => import(/* webpackChunkName: 'Login' */ '@/views/operatePage/login') // 登录界面
const userInfor = () => import(/* webpackChunkName: 'userInfor' */ '@/views/operatePage/userInformation') // 个人信息
const Index = () => import(/* webpackChunkName: 'Index' */ '@/views/operatePage/hjpt-index') // 首页
const HjptFullScreen = () => import(/* webpackChunkName: 'hjptFullScreen' */ '@/views/operatePage/hjpt-fullScreen') // 全屏页

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
//解决vue路由重复导航错误
//获取原型对象上的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export const constantRouterMap = [{
  path: '/',
  component: Layout,
  redirect: '/index',
  name: '首页',
  hidden: true,
  children: [{ path: 'index', component: Index }]
},{
  path: '/login',
  name: 'login',
  meta: {
    title: '致程标准化后台'
  },
  component: Login
},{
  path: '/hjptFullScreen',
  name: 'hjptFullScreen',
  meta: {
    title: '全屏首页'
  },
  component: HjptFullScreen
},{
  path: '/general',
  component: Layout,
  name: '通用页面',
  children: [
    { path: 'userInfor', component: userInfor, name: '个人信息' }
  ]
}]

// 温控中心
const monitor = () => import(/* webpackChunkName: 'monitor' */ '@/views/temperatureControl/monitor') // 温控监控 
const monitorData = () => import(/* webpackChunkName: 'monitorData' */ '@/views/temperatureControl/monitor-data') // 温控数据详情 
const monitorGraph = () => import(/* webpackChunkName: 'monitorGraph' */ '@/views/temperatureControl/monitor-graph') // 温控曲线图
const myAttention = () => import(/* webpackChunkName: 'myAttention' */ '@/views/temperatureControl/myAttention') // 我的关注
const alarmProcessing = () => import(/* webpackChunkName: 'alarmProcessing' */ '@/views/temperatureControl/alarmProcessing') // 报警处理
const orderManage = () => import(/* webpackChunkName: 'orderManage' */ '@/views/temperatureControl/orderManage') // 订单管理
const newOrder = () => import(/* webpackChunkName: 'newOrder' */ '@/views/temperatureControl/newOrder') // 新建订单
const monitreport = () => import(/* webpackChunkName: 'monitreport' */ '@/views/temperatureControl/monitreport') // 监测报表
const RouterTemperature = {
  path: '/temperature',
  component: Layout,
  name: '温控中心',
  // icon: 'icon-chushihuashezhi',
  code: 'TEM',
  children: [
    { path: 'monitor', component: monitor, name: '温控监控', code: 'TEM_MON', meta: { code: 'TEM_MON' } }, 
    { path: 'monitorData', component: monitorData, name: '数据详情', hidden: true }, 
    { path: 'monitorGraph', component: monitorGraph, name: '温控曲线图', hidden: true }, 
    { path: 'myAttention', component: myAttention, name: '我的关注', code: 'TEM_ATE', meta: { code: 'TEM_ATE' } }, 
    { path: 'alarmProcessing', component: alarmProcessing, name: '报警处理', code: 'TEM_ALA', meta: { code: 'TEM_ALA' } },
    { path: 'orderManage', component: orderManage, name: '订单管理', code: 'TEM_ORD', meta: { code: 'TEM_ORD' } },
    { path: 'newOrder', component: newOrder, name: '新建订单', hidden: true }, 
    { path: 'monitreport', component: monitreport, name: '监测报表', code: 'TEM_REP', meta: { code: 'TEM_REP' } },
  ]
}

// 设备中心
const equipmentManage = () => import(/* webpackChunkName: 'equipmentManage' */ '@/views/equipmentCenter/equipmentManage') // 设备管理  
const newEquipment = () => import(/* webpackChunkName: 'newEquipment' */ '@/views/equipmentCenter/newEquipment') // 新建管理   
const expireVerify = () => import(/* webpackChunkName: 'expireVerify' */ '@/views/equipmentCenter/expireVerify') // 到期校验
const RouterEquipment = {
  path: '/equipment',
  component: Layout,
  name: '设备中心',
  icon: 'icon-chushihuashezhi',
  code: 'EQU',
  children: [
    { path: 'equipmentManage', component: equipmentManage, name: '设备管理', code: 'EQU_MAN', meta: { code: 'EQU_MAN' } },
    { path: 'newEquipment', component: newEquipment, name: '新建设备', hidden: true },
    { path: 'expireVerify', component: expireVerify, name: '到期校验', code: 'EQU_VRE', meta: { code: 'EQU_VRE' } },
  ]
}

// 资源管理
const projectManage = () => import(/* webpackChunkName: 'projectManage' */ '@/views/resourceManage/projectManage') // 项目管理
const newProject = () => import(/* webpackChunkName: 'newProject' */ '@/views/resourceManage/newProject') // 新建项目  
const resourceGroup = () => import(/* webpackChunkName: 'resourceGroup' */ '@/views/resourceManage/resourceGroup') // 资源分组
const RouterResource = {
  path: '/resource',
  component: Layout,
  name: '资源管理',
  icon: 'icon-ziyuanguanli',
  code: 'RES', 
  children: [
    { path: 'projectManage', component: projectManage, name: '项目管理', code: 'RES_PJT', meta: { code: 'RES_PJT' } },
    { path: 'newProject', component: newProject, name: '新建项目', hidden: true },
    { path: 'resourceGroup', component: resourceGroup, name: '资源分组', code: 'RES_GRO', meta: { code: 'RES_GRO' } },
  ]
}

// 系统设置
const companyInfo = () => import(/* webpackChunkName: 'companyInfo' */ '@/views/systemSetting/companyInfo') // 公司信息
const organizationalStructure = () => import(/* webpackChunkName: 'organizationalStructure' */ '@/views/systemSetting/organizationalStructure')  // 组织架构
const roleAuthority = () => import(/* webpackChunkName: 'roleAuthority' */ '@/views/systemSetting/roleAuthority') // 角色权限
const jobTitle = () => import(/* webpackChunkName: 'jobTitle' */ '@/views/systemSetting/jobTitle') // 职务名称
const systemTopic = () => import(/* webpackChunkName: 'systemTopic' */ '@/views/systemSetting/systemTopic') // 系统主题
const logManage = () => import(/* webpackChunkName: 'logManage' */ '@/views/systemSetting/logManage') // 日志管理 

const RouterSystem = {
  path: '/system',
  component: Layout,
  name: '系统管理',
  icon: 'icon-xitongguanli',
  code: 'SYS', // 权限编码，后台拿的
  children: [
    { path: 'companyInfo', component: companyInfo, name: '公司信息', code: 'SYS_CMP', meta: { code: 'SYS_CMP' } },
    { path: 'organizationalStructure', component: organizationalStructure, name: '组织架构', code: 'SYS_OGN', meta: { code: 'SYS_OGN' } },
    { path: 'roleAuthority', component: roleAuthority, name: '角色权限', code: 'SYS_ROL', meta: { code: 'SYS_ROL' } },
    { path: 'jobTitle', component: jobTitle, name: '职务名称', code: 'SYS_JOB', meta: { code: 'SYS_JOB' } },
    { path: 'systemTopic', component: systemTopic, name: '系统主题', code: 'SYS_TEM', meta: { code: 'SYS_TEM' } },
    { path: 'logManage', component: logManage, name: '日志管理', code: 'SYS_LOG', meta: { code: 'SYS_LOG' } },
  ]
}

export const asyncRouterMap = [RouterResource, RouterSystem, RouterEquipment, RouterTemperature] // 用来存放菜单栏里面的路由
let router = new VueRouter({
  mode: 'hash',
  scrollBehavior: () => ((document.querySelector('.main_wrapper') || document.querySelector('body')).scrollTop = 0), // 每次跳转路由都会滚动到最上面
  routes: constantRouterMap.concat(asyncRouterMap) // 将基础路由和菜单路由合并
})


// 每次跳转成功后，都会更新store
router.afterEach((to, from) => {
  // console.log("totototot",to)
  store.commit('setLoading', false)
  if (window._axiosPromiseArr === undefined) {
    window._axiosPromiseArr = []
  }
  // console.log('取消请求的拦截', window._axiosPromiseArr)
  // 循环缓存的请求 取消标识数组 取消所有关联的请求
  window._axiosPromiseArr.forEach((ele, index) => {
    // 失败函数中返回自定义错误信息
    ele.cancel()
    console.log(ele)
    delete window._axiosPromiseArr[index]
  })
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // } else {
  //   document.title = '致程运营平台'
  // }
  if (to.name && to.name !== 'login' && to.name !== 'hjptFullScreen' ) {
    store.dispatch('addVisitedViews', to)
  }
})
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})

export default router
